<template>
  <el-main>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="标题：" prop="title">
        <el-input type="text" placeholder="请输入vr标题" v-model="ruleForm.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="上传主图：" prop="picture">
        <ReadyUploadSource
          :showStyle="{
            width: '140px',
            height: '200px',
          }"
          type="image"
          :path="ruleForm.picture"
          @removeThis="() => (ruleForm.picture = '')"
          @getSource="val => (ruleForm.picture = val.path)"
        ></ReadyUploadSource>
      </el-form-item>
      <el-form-item label="vr地址：" prop="vr_links">
        <el-input type="text" placeholder="请输入vr地址" v-model="ruleForm.vr_links" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="是否展示：">
        <el-switch v-model="ruleForm.is_show" :active-value="2" :inactive-value="1"></el-switch>
      </el-form-item>
      <el-form-item label="排序：">
        <el-input type="text" v-model="ruleForm.rank" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import Preservation from '@/components/preservation';
import _ from 'lodash';
export default {
  components: {
    ReadyUploadSource,
    Preservation,
  },
  data() {
    function validatePic(rule, value, callback) {
      if (!value) {
        callback(new Error('请选择一张主图'));
      } else {
        callback();
      }
    }
    return {
      vr_id: 0,
      ruleForm: {
        title: '',
        picture: '',
        vr_links: '',
        is_show: 1,
        rank: 0,
      },
      rules: {
        title: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        vr_links: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        picture: [{ required: true, validator: validatePic, trigger: 'change' }],
      },
    };
  },
  created() {
    if (this.$route.query.id) {
      this.vr_id = this.$route.query.id;
      this.getInfo();
    }
  },
  methods: {
    getInfo() {
      this.$axios
        .post(this.$api.VR.info, {
          id: this.vr_id,
        })
        .then(res => {
          if (res.code === 0) {
            this.ruleForm.title = res.result.title;
            this.ruleForm.picture = res.result.picture;
            this.ruleForm.vr_links = res.result.vr_links;
            this.ruleForm.is_show = res.result.is_show;
            this.ruleForm.rank = res.result.rank;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    preservation() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = _.cloneDeep(this.ruleForm);
          let path = this.$api.VR.add;
          if (this.vr_id) {
            obj.id = this.vr_id;
            path = this.$api.VR.edit;
          }
          this.$axios.post(path, obj).then(res => {
            if (res.code === 0) {
              this.$router.push('/extension/VRshow');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  padding-top: 40px;
  min-height: 100%;
  .el-form {
    .el-input {
      width: 400px;
    }
  }
}
</style>
